<template>
  <div class="flex gap-8 w-full">
    <div class="w-full shadow-sidebar p-8">
      <div class="sm:flex justify-between">
        <h1 class="font-semibold text-xl text-primary leading-9 -mb-3 whitespace-nowrap">Transaction History</h1>
        <div class="flex gap-4 lg:gap-8 my-4 sm:my-0">
          <div class="flex">
            <select
              name="status"
              class="border border-gray-300 text-gray-900 text-sm rounded-md block w-full px-2.5 py-0.5"
              v-model="filter.status">
              <option value="">Select Status</option>
              <option value="SUCCESS">SUCCESS</option>
              <option value="FAILED">FAILED</option>
              <option value="UNPAID">UNPAID</option>
            </select>
          </div>
          <v-date-picker
            color="green"
            class="inline h-full"
            :masks="{ input: 'DD / M / YYYY' }"
            v-model="date.awal"
            @input="getHistoryTransactions"
            :maxDate="date.akhir"
            :is-required="true">
            <template v-slot="{ inputValue, togglePopover }">
              <div
                class="flex space-x-2 justify-center items-center bg-f5 rounded px-3 py-1 cursor-pointer group"
                @click="togglePopover()">
                <img src="../../assets/icons/ic-calendar.svg" alt="" />
                <input
                  class="text-black text-xs font-semibold bg-f5 whitespace-nowrap leading-none -mb-ii outline-none group-hover:text-primary w-16 cursor-pointer"
                  :value="inputValue"
                  disabled />
              </div>
            </template>
          </v-date-picker>
          <v-date-picker
            color="green"
            class="inline h-full"
            :masks="{ input: 'DD / M / YYYY' }"
            v-model="date.akhir"
            @input="getHistoryTransactions"
            :minDate="date.awal"
            :maxDate="maxDate"
            :is-required="true">
            <template v-slot="{ inputValue, togglePopover }">
              <div
                class="flex space-x-2 justify-center items-center bg-f5 rounded px-3 py-1 cursor-pointer group"
                @click="togglePopover()">
                <img src="../../assets/icons/ic-calendar.svg" alt="" />
                <input
                  class="text-black text-xs font-semibold bg-f5 whitespace-nowrap leading-none -mb-ii outline-none group-hover:text-primary w-16 cursor-pointer"
                  :value="inputValue" />
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="flex gap-4 lg:gap-8 my-4 font-semibold">
        <button
          @click="filter.type = ''"
          class="rounded w-28 px-4"
          :class="{
            'bg-white text-gray-500 border border-gray-500': filter.type != '',
            'bg-primary text-white': filter.type == '',
          }">
          ALL
        </button>
        <button
          @click="filter.type = 'PAYMENT'"
          class="rounded w-28 px-4"
          :class="{
            'bg-white text-gray-500 border border-gray-500': filter.type != 'PAYMENT',
            'bg-primary text-white': filter.type == 'PAYMENT',
          }">
          PAYMENT
        </button>
        <button
          @click="filter.type = 'REFUND'"
          class="rounded w-28 px-4"
          :class="{
            'bg-white text-gray-500 border border-gray-500': filter.type != 'REFUND',
            'bg-primary text-white': filter.type == 'REFUND',
          }">
          REFUND
        </button>
        <button
          @click="filter.type = 'TOP_UP'"
          class="rounded w-28 px-4"
          :class="{
            'bg-white text-gray-500 border border-gray-500': filter.type != 'TOP_UP',
            'bg-primary text-white': filter.type == 'TOP_UP',
          }">
          TOP UP
        </button>
        <button
          @click="filter.type = 'CASHBACK'"
          class="rounded w-28 px-4"
          :class="{
            'bg-white text-gray-500 border border-gray-500': filter.type != 'CASHBACK',
            'bg-primary text-white': filter.type == 'CASHBACK',
          }">
          CASHBACK
        </button>
      </div>
      <div class="" v-if="!loadingRecent">
        <div class="mt-8 grid items-center justify-items-center text-lg font-bold" v-if="recents.length <= 0">
          <img src="@/assets/no-transaction.png" class="w-32" />
          <span>No Transaction Yet</span>
        </div>
        <div v-else>
          <div v-for="(datas, index) in recents" :key="index">
            <p class="font-semibold text-base text-primary mb-2 mt-5 capitalize">
              {{ datas.header }}
            </p>
            <div class="space-y-3 md:space-y-5">
              <div v-for="item in datas.items" :key="item.id">
                <div class="flex flex-col rounded-md shadow-sidebar">
                  <div class="flex flex-col p-4 gap-4 w-full">
                    <div class="flex justify-between font-lg font-bold">
                      <span>{{ item.type }}</span>
                      <span class="text-primary">{{ item.amount }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span> with {{ item.source_of_funds }} </span>
                      <span
                        class="py-1 px-6 text-white rounded-md"
                        :class="{ 'bg-primary': item.status === 'Success', 'bg-red-500': item.status === 'Failed', 'bg-yellow-400': item.status === 'Unpaid' }">
                        {{ item.status }}
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span>
                        {{ item.created_at }}
                      </span>
                      <span class="cursor-pointer" @click="item.show_detail = !item.show_detail">
                        Detail <font-awesome-icon :icon="['fas', item.show_detail ? 'caret-up' : 'caret-down']" />
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col p-4 gap-4 w-full border-t border-gray-200" v-if="item.show_detail">
                    <div class="grid">
                      <span>Description</span>
                      <span>{{ item.description }}</span>
                    </div>
                    <div class="grid grid-cols-3 gap-2">
                      <div class="grid">
                        <span>Reference No</span>
                        <span>{{ item.reference_no }}</span>
                      </div>
                      <div class="grid">
                        <span>
                          Expired Date
                        </span>
                        <span>
                          {{ item.expired_at }}
                        </span>
                      </div>
                      <div class="grid">
                        <span>
                          Payment Date
                        </span>
                        <span>
                          {{ item.paid_at }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-72vh overflow-y-scroll mt-8" v-else>
        <div class="space-y-3 md:space-y-5">
          <div
            class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
        </div>
        <div class="space-y-3 md:space-y-5 mt-9">
          <div
            class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-48 transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
          <div
            class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- <detail-transaction ref="detailTransaction"></detail-transaction> -->
</template>

<script>
import RecentTransactions from "../../components/RecentTransactions";
import { NumberFormat } from "../../utils";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
const firstDate = firstDay.toISOString().slice(0, 10);

export default {
  name: "Transactions",
  components: {
    RecentTransactions,
    DetailTransaction: () => import("@/components/layout/DetailTransaction.vue"),
  },
  data: () => ({
    recents: [],
    loadingRecent: false,
    date: {
      awal: firstDate,
      akhir: new Date().toISOString().slice(0, 10),
    },
    maxDate: date,
    transactionDetails: null,
    active: null,
    filter: {
      status: "",
      type: "",
    },
  }),
  methods: {
    showCurrent() {},
    numberFormat: (num) => NumberFormat(num),
    positiveNominal: (num) => Math.abs(num),
    async getHistoryTransactions() {
      this.loadingRecent = true;
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http.get(`${this.$apiTripweWeb}/dana/accounts/histories`, {
          params: {
            page: 1,
            per_page: 100,
            start_date: new Date(this.date.awal).toISOString().slice(0, 10),
            end_date: new Date(this.date.akhir).toISOString().slice(0, 10),
            type: this.filter.type,
            status: this.filter.status,
          },
        });
        if (res.data.status === 200) {
          const data = res.data.data.map((item) => {
            const newItem = item.items.map((item2) => {
              item2.show_detail = false;
              return item2;
            });
            item.items = newItem;
            return item;
          });
          this.recents = data;
        } else {
          this.recents = [];
          this.$toasted.global.success("No transaction history");
        }
      } catch (error) {
        console.log(error);
        this.$toasted.global.error("Fail to load recent transaction");
      } finally {
        this.loadingRecent = false;
        this.$store.commit("setOverlayLoading", false);
      }
    },
    setActive(active) {
      return (this.active = active);
    },
  },

  created() {
    this.getHistoryTransactions();
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Balance", routeName: "TripwePay" },
      { text: "Transactions", routeName: "Transactions" },
    ]);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    transactionDetailsPaymentMethod() {
      return "Balance";
    },
  },
  watch: {
    "filter.status": function(newVal, oldVal){
      this.getHistoryTransactions()
    },
    "filter.type": function(newVal, oldVal){
      this.getHistoryTransactions()
    }
  }
};
</script>
